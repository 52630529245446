import { Webchat, WebchatProvider, getClient } from "@botpress/webchat";
import { buildTheme } from "@botpress/webchat-generator";
import { useState, useEffect } from "react";
import './App.css';

const { theme, style } = buildTheme({
  themeName: "prism",
  themeColor: "#b3cfe9",
});

// Configuration object for the chatbot
const config = {
  composerPlaceholder: "Hi!",
  botName: "PolicyPal",
  botAvatar: "/avatar.png",
  botDescription:
    "Streamline your writing with automated assistance, saving time and effort in creating documents and policies.",
  privacyPolicy: {
    title: "Contact Rawan",
    link: "skype:live:.cid.233a3b1b05ef3c76?chat",
  },
  enableHistory: true,
};

const clientId = "cbc376c4-895c-4bfa-9c07-a609c7fab86d";

export default function App() {
  const client = getClient({ clientId });
  const [isWebchatOpen, setIsWebchatOpen] = useState(true);

  useEffect(() => {
    const handleRestartConversation = () => {
      const webchatStore = client.store;
      if (webchatStore && webchatStore.restartConversation) {
        webchatStore.restartConversation();
      }
    };

    const targetElement = document.querySelector('.bpHeaderContentActionsContainer :nth-child(2)');
    if (targetElement) {
      const handleClick = () => {
        console.log("Clicked!");
        window.open("https://docs.google.com/document/d/1dqUk1-9y8uGsgctoJeeyLqjAgZmX9DGOi3_BSoSVkg8/edit#heading=h.h63m7ydf9xfr", '_blank');
      };

      targetElement.addEventListener('click', handleClick);

      return () => {
        targetElement.removeEventListener('click', handleClick);
      };
    }

    const initializeWebChat = () => {
      if (window.botpressWebChat) {
        window.botpressWebChat.mergeConfig({ showConversationsButton: true });
      }
    };

    if (document.readyState === "complete") {
      initializeWebChat();
    } else {
      window.addEventListener("load", initializeWebChat);
    }

    return () => {
      window.removeEventListener("load", initializeWebChat);
    };
  }, [client]);

  useEffect(() => {
    const container = document.querySelector('.bpHeaderContentActionsContainer ');
    if (container) {
      const tooltip = document.createElement('div');
      tooltip.textContent = "PolicyPal Userguide";
      tooltip.style.position = 'absolute';
      tooltip.style.top = '55px';
      tooltip.style.left = '95.5%';
      tooltip.style.transform = 'translateX(-50%)';
      tooltip.style.backgroundColor = 'black';
      tooltip.style.color = 'white';
      tooltip.style.padding = '5px';
      tooltip.style.borderRadius = '5px';
      tooltip.style.whiteSpace = 'nowrap';
      tooltip.style.fontSize = '12px';
      tooltip.style.zIndex = '10';
      tooltip.style.opacity = '0';
      tooltip.style.transition = 'opacity 0.3s';
      tooltip.style.pointerEvents = 'none';

      container.appendChild(tooltip);

      const handleMouseEnter = () => {
        tooltip.style.opacity = '1';
        console.log("in");
        
      };

      const handleMouseLeave = () => {
        tooltip.style.opacity = '0';
        console.log("leave");
        
      };

      container.addEventListener('mouseenter', handleMouseEnter);
      container.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        container.removeEventListener('mouseenter', handleMouseEnter);
        container.removeEventListener('mouseleave', handleMouseLeave);
      };
    } else {
      console.error('Element not found.');
    }
  }, []);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <style>{style}</style>

      <WebchatProvider
        key={JSON.stringify(config)}
        theme={theme}
        configuration={config}
        client={client}
      >
        <div
          className="text-red-500"
          style={{
            display: isWebchatOpen ? "block" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            color: "red",
          }}
        >
          <Webchat />
        </div>
      </WebchatProvider>
    </div>
  );
}
